.prompt
{
	padding: $rule/2;
	border: 1px solid $color03;
	background-color: $color02;
	color: $color03;
	animation: fadeup .7s $ease-out-quart;
}
.product-info
{
	p
	{
		margin-bottom: $rule/2;
		&:last-of-type
		{
			margin-bottom: $rule*2;
		}
	}
	border-bottom: 3px solid $color03;
}
.prompt-text
{
	width: 80%;
	margin: $rule 0;
	color: lighten( $color06,30%);

}
.prompt-tools
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.prompt-inputs
{
	margin-right: $rule/2;
	input
	{
		width: 100%;
		margin-right: $rule*2;
		padding: $rule/6 $rule/4;
		border: 1px solid $color03;
		color: $color03;
		background-color: transparent;
	}
}
.prompt-footer
{
	display: flex;
	justify-content: space-between;
}
.btn-prompt-cancel
{
	display: block;
	padding: $rule/6 $rule/2;
	border: 2px solid $color03;
	color: $color03;
	cursor: pointer;
}
.btn-prompt-ok
{
	display: block;
	margin-right: $rule/2;
	padding: $rule/6 $rule/2;
	border: 2px solid $color03;
	color: $color03;
	cursor: pointer;
}