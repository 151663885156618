html
{
	font-size: 93.8%; // 15px basis == 1rem
}

*
{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-weight: inherit;
	font-style: inherit;
}

a
{
	text-decoration: none;
	font-weight: inherit;
	color: inherit;
	img
	{
		border: 0;
	}
}

li
{
	list-style: none;
}

fieldset
{
	border: 0;
}

button
{
	border: 0;
	text-align: left;
	background: transparent;
}

*:focus
{
	outline: none;
}

button, input, textarea
{
	font: inherit;
}
