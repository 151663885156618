@mixin default-font
{
	font-family: Roboto , sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.8;
}
//BLOCKQUOTES
@mixin blockquote-text
{
	font-size: 1.7rem;
	font-weight: 100;
	line-height: 2.5;
	color: $color02;
}
@mixin blockquote-text-small
{
	@include blockquote-text;
	font-size: 1.3rem;
	line-height: 3;
}
//RIBBON
@mixin ribbon-element-title
{
	font-size: 2.5rem;
	font-weight: 100;
	text-transform: lowercase;
}
//NEWS
@mixin title
{
	font-size: 2.8rem;
	font-weight: 100;
}
@mixin section-title
{
	@include default-font;
	font-size: 1.2rem;
	font-weight: bold;
}
@mixin page-subtitle
{
	margin-bottom: $rule/2;
	@include default-font;
	border-bottom: 8px solid $color02;
	font-size: 2.5rem;
	line-height: 2;
	font-weight: 100;
	color: $color01;
}
@mixin pageblock-title
{
	padding-bottom: $rule/2;
	@include default-font;
	font-size: 1.5rem;
	font-weight: 100;
	color: $color02;
}
@mixin collection-title
{
	margin-bottom: $rule/2;
	@include default-font;
	border-bottom: 8px solid $color02;
	font-size: 2.5rem;
	font-weight: 100;
	line-height: 2;
}