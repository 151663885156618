.cookie-notice
{
	$padding: $rule / 6 0;
	$font-size: .85rem;

	$opacity: 1;
	$background-color: $color02;
	$text-color: $color03;
	$text-align: left;

	$button-background-color: $color03;
	$button-text-color: $color02;
	$button-border-radius: 0;

	$link-text-color: $color03;

	position: relative;

	padding: $padding;
	opacity: $opacity;
	font-size: $font-size;
	background-color: rgba( $background-color, $opacity );
	color: $text-color;

	.cookie-notice-content
	{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.cookie-notice-text
	{
		text-align: $text-align;
		padding-right: $rule;

		a
		{
			border-bottom: 1px solid $link-text-color;
			color: $link-text-color;
		}
	}

	#cookie-notice-agree-btn
	{
		display: block;
		padding: $rule / 12 $rule / 2;
		border: 2px solid $color03;
		text-transform: uppercase;
		background-color: $color02;
		color: $color03;
		cursor: pointer;
	}
}
