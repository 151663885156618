#product-collection-wrapper
{
	@include breakpoint(3)
	{
		padding: $rule 0;
	}
	h2
	{
		@include collection-title;
	}

}
#products
{
	@include breakpoint(2)
	{
		@include grid( 2, 2rem );
	}
	@include breakpoint(5)
	{
		@include grid( 3, 3rem );
	}
}
.product
{
	>div
	{
		flex-grow: 1;
		margin-bottom: $rule;
		padding: $rule/3;
		background-color: $highlight_color;
		@include breakpoint(3)
		{
			margin-bottom: 0;
		}
		.img-wrap
		{
			img
			{
				display: block;
				width: 100%;
				padding-bottom: $rule/4;
				border-bottom: 1px solid #ccc;
			}
		}
		.product-info
		{
			padding: $rule/4 0;
			.code
			{
				display: block;
				padding: $rule/4 0;
				text-transform: uppercase;
				color: $ribbon_color;
			}

			.product-info-content
			{
				display: flex;
				justify-content: space-between;
				align-items: center;
				h3
				{
					width: 55%;
				}
				a
				{
					display: inline-block;
					width: 35%;
					padding: $rule/4 $rule/2;
					border: 1px solid #ccc;
					text-align: center;
					cursor: pointer;
				}
			}
		}
	}
}
#pig
{
	cursor: pointer;
}
.fillcolor
{
	transition: fill 0.3s ease;
	fill: $color02;
}

.fillout
{
	transition: fill 0.3s ease;
}
.active-part
{
	fill: $color02;
}
