.hamburger-box
{
	position: absolute;
	top: $rule/4;
	right: 0;
	display: inline-block;
	width: $rule;
	height: 24px;
	margin-top: $rule*1.5;
	cursor: pointer;

	.hamburger-inner
	{
		top: 50%;
		display: block;
		width: $rule;
		height: 3px;
		background-color: $color02;
		transition: background-color 0.2s 0.25s ease-in;
		&:before
		{
			content: '';
			position: absolute;
			top: -10px;
			left: 0;
			display: block;
			width: $rule;
			height: 3px;
			background-color: $color02;
			transition: transform 0.2s cubic-bezier( 0.6, 0.04, 0.98, 0.36 ), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in;
		}
		&:after
		{
			content: '';
			position: absolute;
			top: 10px;
			right: 0;
			bottom: -10px;
			display: block;
			width: $rule;
			height: 3px;
			background-color: $color02;
			transition: transform 0.2s cubic-bezier( 0.6, 0.04, 0.98, 0.36 ), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in;
		}
	}
	&:before
	{
		top: 0;
	}
	&:after
	{
		bottom: 0;
	}

	@include breakpoint( 3 )
	{
		display: none;
	}
}
body.open-nav
{
	#main-menu
	{
		ul
		{
			display: flex;
			flex-direction: column;
		}
		.hamburger-box
		{
			.hamburger-inner
			{
				background-color: $color03;
				&:before
				{
					top: -80px;
					left: -80px;
					transform: translate3d( 80px, 80px, 0) rotate( 45deg );
					transition: left 0.2s ease-out, top .05s 0.2s linear, transform 0.2s 0.25s cubic-bezier( 0.07, 0.82, 0.16, 1 );
				}
				&:after
				{
					top: -80px;
					right: -80px;
					transform: translate3d( -80px, 80px, 0) rotate( -45deg );
					transition: right 0.2s ease-out, top  0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier( 0.07, 0.82, 0.16, 1 );
				}
			}
		}
	}

}