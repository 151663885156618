@mixin grid-item-width( $width: 100% )
{
	width: $width;
}

@mixin grid-items-per-row( $items-per-row: 1 )
{
	$item-width: 100% / $items-per-row;

	>*
	{
		@include grid-item-width( $item-width );
	}
}

@mixin grid-responsive( $queries )
{
	@each $breakpoint, $items-per-row in $queries
	{
		@media screen and ( min-width: $breakpoint )
		{
			@include grid-items-per-row( $items-per-row );
		}
	}
}

@mixin grid( $items-per-row: 1, $gap: 1rem, $queries: () )
{
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-top: -$gap;
	margin-left: -$gap / 2;
	margin-right: -$gap / 2;
	@include grid-items-per-row( $items-per-row );

	>*
	{
		float: left;
		display: flex;
		flex-direction: column;
		padding:
		{
			top: $gap;
			right: $gap / 2;
			bottom: 0;
			left: $gap / 2;
		}
	}

	@include grid-responsive( $queries );
}
