.styled-checkbox
{
	$padding: 0 0 0 25%;
	$font-size: .9rem;
	$margin: $rule / 2 0 $rule 0;
	$border-width: 1px;
	$border-color: $color02;
	$checkbox_background_color: $color02;
	$error-checkbox-padding: .75rem;

	padding: $padding;
	margin: $margin;
	font-size: $font-size;
	touch-callout: none;
	user-select: none;

	.styled-checkbox-wrap
	{
		position: relative;
		padding-left: 2.75rem;
	}

	label
	{
		display: block;
		margin-bottom: 0;
		padding: 0;
		cursor: pointer;
		text-transform: initial;
	}

	label:before,
	label:after
	{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;
		box-sizing: border-box;
		width: 1rem;
		height: 1rem;
	}

	label:before
	{
		border: $border-width solid $border-color;
		vertical-align: middle;
	}

	label:after
	{
		transform: scale( .2 );
		transition: background-color .1s, transform .15s;
	}

	input:checked + label:after
	{
		background-color: $checkbox_background_color;
		transform: scale( 1 );
	}

	input
	{
		position: absolute;
		left: 0;
		opacity: 0;
	}

	label span
	{
		margin-left: $rule / 6;
		color: $border-color;
	}

	label a
	{
		text-decoration: underline;
	}
}