.header-wrapper
{
	position: relative;
	overflow: hidden;
	padding-bottom: $rule;
}

#subnav-wrapper
{
	background-color: $secondary_color;

	nav
	{
		width: 100%;
		@include breakpoint(3)
		{
			width: 60%;
			margin-left: auto;
		}

		ul
		{
			display: flex;
			flex-wrap: wrap;
			margin-top: $rule*2;
			@include breakpoint(3)
			{
				margin-top: 0;
			}
			li
			{
				position: relative;
				display: block;
				font-size: 1.2rem;
				text-transform: uppercase;
				a
				{
					&:after
					{
						content: attr( title );
					}
					display: block;
					padding: $rule/1.5 $rule;
				}
				&.active
				{
					&:after
					{
						content: '';
						position: absolute;
						z-index: 3;
						bottom: -10px;
						left: 50%;
						width: 0;
						height: 0;
						margin-left: -10px;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-top: 10px solid $secondary_color;

					}
					a
					{
						&:after
						{
							content: attr( title );
							width: 100%;
							border-top: 1px solid $color01;
							border-bottom: 1px solid $color01;

						}
					}
				}
			}
		}
	}
}