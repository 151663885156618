#post-footer-wrapper
{
	overflow: hidden;
	background-color: $footer_color;
	color: $color03;
}
#post-footer
{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: $rule/4 0;
	p
	{
		margin: $rule/4 0;
		color: $color06;
	}
	a
	{
		margin: $rule/4 0;
	}
	@include breakpoint( 2 )
	{
		flex-direction: row;
		justify-content: space-between;
		p, a
		{
			margin: 0;
		}
	}
}