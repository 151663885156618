#main-menu
{
	overflow: hidden;
	margin-top: $rule;
	ul
	{
		display: none;
		li
		{
			margin-bottom: $rule/4;
			text-align: center;
			&.active
			{
				a
				{
					font-weight: bolder;
					color: $color07;
				}
			}
			a
			{
				display: block;
				text-transform: uppercase;
				font-size: 1.2rem;
				color: $color06;
				@include breakpoint(3)
				{
					font-size: 1rem;
				}
			}
		}
	}
	@include breakpoint(3)
	{
		margin-top: 0;
		ul
		{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}