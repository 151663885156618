//::-webkit-scrollbar { width: 10px; }
//::-webkit-scrollbar-thumb { background-color: $color02; }
//::selection { background: $color02; color: $color03; }

html
{
	font-size: 70%;
	@include breakpoint(2)
	{
		font-size: 80%;
	}
	@include breakpoint(3)
	{
		font-size: 90%;
	}
	@include breakpoint(4)
	{
		font-size: 100%;
		line-height: 1.8;
	}
}
body
{
	color: $color01;
	background-color: $color03;
	@include default-font;
}

.wrap
{
	width: 90%;
	margin: 0 auto;
}
.wrapper
{
	width: 90%;
	margin: 0 auto;
}
#content-container
{
	padding: $rule 0;
	background-color: lighten( $bg_color, 20% );
}
.wysywig
{
	strong
	{
		font-weight: 600;
	}

	em
	{
		font-style: italic;
	}

	ul
	{
		margin-left: $rule/2;

		li
		{
			list-style: square;
		}
	}
	a

	{
		font-weight: 600;
		color: $color02;
	}
}