.banner
{
	position: relative;
	overflow: hidden;
	display: none;
	width: 100%;
	height: 0;
	padding-top: 32.07%; //( 270/842 * 100) = 32... HACK
	background-color: lighten( $color05, 30% );

	@include breakpoint(2)
	{
		display: block;
	}
	.bg-image
	{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		animation: kenburns 20s infinite ease-in;
	}
	.text-wrap
	{
		position: absolute;
		z-index: 20;
		top: 40%;
		left: 55%;
		display: none;
		font-size: 2.5rem;
		font-weight: 100;
		line-height: 1.5;
		color: $color01;
		@include breakpoint(4)
		{
			display: block;
		}
		span
		{
			color: lighten( $color05, 30% );
		}
	}
	svg
	{
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
	}
}