.blockquote
{
	padding-bottom: $rule/2;
	@include breakpoint(2)
	{
		padding: $rule 0;
	}
	@include breakpoint(3)
	{
		padding: $rule*2 0;
	}
	p
	{
		display: block;
		width: 90%;
		margin: 0 auto;
		text-align: center;
		@include blockquote-text;
	}
	@include breakpoint(2)
	{
		p
		{
			width: 80%;
		}
	}
	&.small
	{
		padding: $rule 0;
		p
		{
			@include blockquote-text-small;
			background-image: url('img/bg_triangles.svg');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
		}
	}
}