.intro-bg-wrapper
{
	position: relative;
	overflow: hidden;
	display: none;
	width: 100%;
	height: 0;
	padding-top: 18.2%; // (svg height + svg width)* width value (100%) HACK
	background-color: lighten( $color05, 30%);
	@include breakpoint(3)
	{
		display: block;
	}
	.bg-image
	{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom;
		animation: kenburns-alt 20s infinite ease-in;
	}

	svg
	{
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
	}
}