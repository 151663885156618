#team-wrapper
{
	h2
	{
		@include collection-title;
		border-bottom: none;
	}
}
.department-container
{
	h3
	{
		@include pageblock-title;
		padding: 0;
	}
}
.departments
{
	padding: $rule 0;//
	@include breakpoint(3)
	{
		@include grid( 2, $rule);

	}
}
.department
{
	.department-wrap
	{
		padding: $rule/2 0;
		background-color: $color03;
		&.active
		{
			.department-head
			{
				margin: 0 $rule*1.5 $rule/2 $rule*1.5;
				padding-bottom: $rule/2;
				border-bottom: 1px solid $color04;
				.arrow_down
				{
					&:after
					{
						transform: rotate(360deg);
					}
				}
			}
			.members
			{
				display: block;
			}
		}
		.department-head
		{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 $rule*1.5;

			h3
			{
				padding: 0;
			}
			.arrow_down
			{
				position: relative;
				display: block;
				height: $rule;
				width: $rule;
				background-color: $color02;
				cursor: pointer;

				&:after
				{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					display: block;
					height: $rule/2;
					width: $rule/2;
					margin-top: -$rule/4;
					margin-left: -$rule/4;
					background-image: url('img/arrow_down.svg');
					background-repeat: no-repeat;
					background-size: contain;
					transform: rotate(-90deg);
				}
			}
		}
	}
}
.members
{
	display: none;
	padding: 0 $rule*1.5;

	.member
	{
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding: $rule/2 0;
		border-bottom: 1px solid #eeeeee;
		&:last-of-type
		{
			border-bottom: none;
		}
		.member-info
		{
			span
			{
				font-size: 0.6rem;
			}
		}
		span
		{
			display: block;
		}
	}
}