// colors

$color01: #595959;
$color02: #7d1008;
$color03: #ffffff;
$color04: #8e0e0e;
$color05: #82a472; //use with 30% opacity
$color06: #7a7a7a; // nav
$color07: #97130d; // nav

$primary_color: #595959;
$secondary_color: #e2eade;
$footer_color:#333333;
$main_text_color: #000000;
$secondary_text_color:#7d1008;
$ribbon_color: #7d1008;
$highlight_text: #ffffff;
$highlight_color: #ffffff;
$bg_color:#c5c5c6;

$rule: 2.5rem;

// breakpoints

$breakpoints: (
	650px,
	750px,
	1024px,
	1150px,
	1280px,
	1375px,
	1500px,
	1600px,
	1750px,
	1900px,
);
$ease-out-quart: cubic-bezier( 0.16, 0.84, 0.44, 1 );