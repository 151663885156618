#language-select
{
	position: absolute;
	z-index: 10;
	top: 0;
	right: 5%;

	ul
	{
		text-align: right;

		li
		{
			display: inline-block;

			a
			{
				display: block;
				width: $rule;
				height: $rule;
				padding-top: $rule/4;
				font-size: 0.8rem;
				font-weight: 400;
				text-align: center;
				color: $primary_color;
			}

			&.active a
			{
				color: $color03;
				background-color: $color07;
			}
		}
	}
}