#contact-form
{
	overflow: hidden;
	margin-bottom: $rule;

	h3
	{
		@include pageblock-title;
	}

	.form
	{
		.contactform-wrap
		{
			@include breakpoint(3)
			{
				@include grid( 2, 2rem );
				padding: $rule 0;
			}
		}

		fieldset
		{
			padding: $rule/4 0;
			&.has_error
			{
				color: $primary_color;
				input,textarea
				{
					border: 1px solid $color04;
				}
			}
			&.required label:after
			{
				content: '*';
				color: $primary_color;
			}
			label
			{
				line-height: $rule*1.5;
				@include breakpoint(4)
				{
					float: left;
					width: 25%;

				}
			}
			input,textarea
			{
				width: 100%;
				padding: $rule/2 $rule;
				border: 0;
				border-radius: 0;
				background-color: $secondary_color;
				@include breakpoint(4)
				{
					width: 75%;
				}

			}
			textarea
			{
				display: flex;
				min-height: $rule*6;
				resize: vertical;
			}
		}
	}

	.submit
	{
		float: right;
		display: block;
		margin-top: $rule/2;
		padding: $rule/2 $rule*2;
		border: 3px solid $color02;
		text-transform: uppercase;
		background-color: $color02;
		color: $color03;
		cursor: pointer;

		&:hover
		{
			border: 3px solid $color04;
			color: $color04;
			background-color: $color03;
		}
	}
}