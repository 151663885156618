
.normal-block, .special-block
{

	padding: $rule/2 0;
	h3
	{
		@include pageblock-title;
		padding: 0;
		font-size: 2rem;
	}
}
.normal-block-content
{

	h2
	{
		@include page-subtitle;
	}
	h3
	{
		@include pageblock-title;
	}

	.normal-img-wrapper
	{

		img
		{
			width: 100%;
		}
	}
	@include breakpoint(3)
	{
		display: flex;
		>div
		{
			width: 50%;
		}
		.wysywig
		{
			padding-right: $rule/2;
		}
		.normal-img-wrapper
		{
			padding-top: 0;
			padding-bottom: 0;
			padding-left: $rule;
		}
	}
}

.special-block-items
{
	.special-block-item
	{
		margin: $rule/4 0;
		h3
		{
			@include pageblock-title;
		}
	}
	@include breakpoint( 3 )
	{
		@include grid( 3, $rule );
	}
}