body.home
{
	.header-wrapper
	{
		padding: 0;
		border-top: 8px solid $color02;
	}
	header
	{
		margin-top: 0;
		margin-bottom: $rule;
	}
}
.default-header
{
	position: relative;
	z-index: 10;
	height: 8px;
	background-color: $color02;
	h1
	{
		position: absolute;
		top: -$rule/1.5;
		left: $rule*2;
		display: block;
		padding: 0 $rule/4;
		background-color: $color03;
		a
		{
			display: block;
			width: $rule*2;
			height: $rule*2;
			text-indent: -9999px;
			background-image: url(img/logo_small.svg);
			background-repeat: no-repeat;
			background-size: contain;
		}

	}
}
header
{
	position: relative;
	padding-top: $rule;
	margin-top: $rule/2;
	@include breakpoint(3)
	{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: $rule;

		.logo
		{
			width: 40%;
		}
		#nav-wrapper
		{
			width: 60%;
		}
	}
	.logo
	{
		h1
		{
			a
			{
				display: block;
				width: $rule*10;
				height: $rule*2;
				text-indent: -9999px;
				background-image: url(img/jademo_logo.svg);
				background-repeat: no-repeat;
				background-size: contain;
				@include breakpoint(3)
				{
					width: $rule*12;
					height: $rule*2.5;
				}
			}

		}
	}
}
