#product-wrapper
{
	padding: $rule*2 0;
}
#product_nav
{

	@include breakpoint(3)
	{
		display: flex;
		justify-content: space-between;
	}
	.image-nav
	{
		display: block;
		width: 100%;
		svg
		{
			display: block;
		}
		p
		{
			padding-bottom: $rule/2;
			text-align: center;
		}
		@include breakpoint(3)
		{
			width: 30%;
		}
	}
}
.collection-nav
{
	width: 100%;
	margin-top: $rule;
	@include breakpoint(3)
	{
		width: 65%;
	}
	ul
	{
		@include grid( 2 );

		li
		{
			position: relative;
			a
			{
				display: block;
				h2
				{

					z-index: 1;
					text-transform: lowercase;
					color: $color02;
				}
				&:before
				{
					background-color: rgba(#333333,0);
				}

				&:after
				{
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background-color: rgba(#ffffff,0.7);
				}
				@include breakpoint(2)
				{
					&:hover
					{

						&:before
						{
							content: '';
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							background-color: rgba($color02,0.7);

							@include breakpoint(3)
							{
								top: $rule/2;
								right: $rule;
								bottom: $rule/2;
								left: $rule;
							}
						}
						&:after
						{
							content: '';
							background-color: transparent;
						}
					}
				}
			}
			&.active
			{
				a
				{
					&:hover
					{
						&:after
						{
							content: '';
							background-color: transparent;
						}
					}
					&:after
					{
						content: '';
						background-color: transparent;
					}
					h2
					{
						color: $color03;

					}
					&:before
					{
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						background-color: rgba($color02, 0.8);

						@include breakpoint(2)
						{
							top: $rule/2;
							right: $rule;
							bottom: $rule/2;
							left: $rule;
						}
					}
				}

				&:after
				{
					content: '';
					position: absolute;
					z-index: 3;
					bottom: -$rule*1.5;
					left: 50%;
					width: 0;
					height: 0;
					margin-left: -$rule;
					border-left: $rule solid transparent;
					border-right: $rule solid transparent;
					border-top: $rule solid $color02;

				}
			}
			.collection-item
			{
				position: relative;
				img
				{
					display: block;
					width: 100%;
				}
				h2
				{
					position: absolute;
					top: 50%;
					left: 50%;
					font-size: 3rem;
					font-weight: 100;
					transform: translate(-50%, -50%);
				}
				&:hover
				{
					h2
					{
						color: $color03;
					}
				}
			}
		}
	}
}