#ribbon-wrapper
{
	background-color: $color02;
	nav
	{

		ul
		{
			@include breakpoint(2)
			{
				@include grid( 3 );
			}
			li
			{
				a
				{
					position: relative;
					display: block;
					flex-grow: 1;
					padding: $rule $rule/2;
					text-align: center;
					&:after
					{
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						display: block;
						transition: all 0.2s ease-out;
					}
					img
					{
						position: relative;
						z-index: 2;
						display: block;
						width: $rule*3;
						height: $rule*3;
						margin: 0 auto;

					}
					h2
					{
						position: relative;
						z-index: 2;
						margin: $rule 0;
						color: $color03;
						@include ribbon-element-title;
					}
					p
					{
						position: relative;
						z-index: 2;
						font-size: 1.2rem;
						font-weight: 100;
						color: $color03;
					}
					@include breakpoint(3)
					{
						&:hover
						{
							&:after
							{

								content: '';
								position: absolute;
								z-index: 0;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								display: block;
								margin-top: -$rule;
								margin-bottom: -$rule/2;
								background-color: $color04;
							}
						}
					}
				}

			}
		}
	}
}