#certificate-wrapper
{
	margin: $rule 0;
	h2
	{
		@include pageblock-title;
		padding: $rule/2 0;
	}
}
#certificates
{
	@include breakpoint(2)
	{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}
.certificate
{
	margin-bottom: 4%;
	@include breakpoint(2)
	{
		width: 48%;
	}
}
.certificate-head
{
	display: flex;
	justify-content: space-between;
	h3
	{
		@include pageblock-title;
		padding: 0;
	}
	.arrow_down
	{
		position: relative;
		display: block;
		height: $rule;
		width: $rule;
		background-color: $color02;
		cursor: pointer;

		&:after
		{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			height: $rule/2;
			width: $rule/2;
			margin-top: -$rule/4;
			margin-left: -$rule/4;
			background-image: url('img/arrow_down.svg');
			background-repeat: no-repeat;
			background-size: contain;
			transform: rotate(-90deg);
		}
	}
}
.certificate-wrap
{

	flex: 1;
	padding: $rule;
	background-color: $color03;
	&.active
	{
		.certificate-head
		{
			margin-bottom: $rule/2;
			.arrow_down
			{
				&:after
				{
					transform: rotate(360deg);
				}
			}
		}
		.certificate-content-wrap
		{
			display: flex;
			min-height: $rule*10;
		}
	}
}
.certificate-content-wrap
{
	display: none;
}
.certificate-media
{
	width: 40%;
	padding-right: $rule*1.5;
	.logo-wrapper
	{
		margin-bottom: $rule;
		background-color: $color03;
		img
		{
			display: block;
			width: 100%;
			background-color: $color03;
		}
	}
	.certificate-download
	{
		display: block;
		text-align: center;

		a
		{
			position: relative;
			display: block;
			padding: $rule 0;
			text-indent: -9999px;
			background-color: lighten($color01,50%);
			&:hover
			{
				text-indent: 0;
				background-color: $color04;
				color: $color03;
				&:after
				{
					display: none;
				}
			}
			&:after
			{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				height: $rule*1.5;
				width: $rule*1.5;
				margin-top: -$rule/1.5;
				margin-left: -$rule/1.5;
				background-image: url('img/download.svg');
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
}
.certificate-info
{
	width: 70%;
}