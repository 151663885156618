.overlay
{
	position: fixed;
	z-index: 10001;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: overlay-show .7s $ease-out-quart;
	animation-fill-mode: both;
}