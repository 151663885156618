footer
{
	margin: 0 auto;
	padding: $rule/2 0;
	@include breakpoint( 2 )
	{
		@include grid (3 );
		align-items: center;
		margin: 0;
	}
}
#footer-pages
{
	display: none;
	ul
	{
		li
		{
			margin-bottom: $rule/4;
			a
			{
				text-transform: lowercase;
				&:hover
				{
					color: $color07;
				}
			}
		}
	}
	@include breakpoint( 2 )
	{
		display: block;
	}
}
#footer-certificates
{
	text-align: center;
	h4
	{
		padding-bottom: $rule/4;
	}
	ul
	{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		li
		{
			margin-bottom: $rule/2;
			margin-right: $rule/6;
			a
			{
				display: block;
				height: $rule*2;
				width: $rule*2;
				img
				{
					display: block;
					width: 100%;
					margin: 0 auto;
				}
			}
		}
	}
	@include breakpoint( 2 )
	{
		text-align: left;
		ul
		{

			li
			{
				margin-bottom: 0;
				margin-right: $rule/6;
			}
		}
	}
}
#footer-contact
{
	text-align: center;
	line-height: 2rem;
	@include breakpoint( 2 )
	{
		text-align: right;
	}
}
