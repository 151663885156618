#contact-wrapper
{
	h2
	{
		@include title;
	}
}
#contact
{
	@include breakpoint(2)
	{
		display: flex;
		margin: $rule 0;
	}
	#contact-information
	{
		margin-bottom: $rule;
		@include breakpoint(2)
		{
			width: 30%;
		}
		h3
		{
			@include pageblock-title;
			margin-bottom: $rule/2;
		}
		address
		{
			line-height: 2;
		}
	}
	.map
	{
		height: 200Px;
		width: 100%;
		@include breakpoint(3)
		{
			width: 70%;
			height: 400Px;
		}//
	}
}
