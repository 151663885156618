@keyframes fadein
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}
@keyframes kenburns
{
	0%
	{
		opacity: 0;
	}

	5%
	{
		opacity: 1;
	}

	95%
	{
		transform: scale3d( 1.5, 1.5, 1.5 ) translate3d( -150px, 0, 0 ) rotate(0.1deg);
		opacity: 1;
	}

	100%
	{
		transform: scale3d( 2, 2, 2 ) translate3d( 0, 0, 0 )  rotate(0.1deg);
		opacity: 0;
	}
}
@keyframes kenburns-alt
{
	0%
	{
		opacity: 0;
	}

	5%
	{
		opacity: 1;
	}

	95%
	{
		transform: scale3d( 1.5, 1.5, 1.5 ) translate3d( 190px, 0, 0 ) rotate(0.1deg);
		opacity: 1;
	}

	100%
	{
		transform: scale3d( 2, 2, 2 ) translate3d( 170px, 0, 0 ) rotate(0.1deg);
		opacity: 0;
	}
}
@keyframes overlay-show
{
	to
	{
		background-color: rgba( $color03, 0.85 );
	}
}