#news-wrapper
{
	overflow: hidden;
	padding: $rule/2 0;
	background-color: lighten($color05, 30%);
	h2
	{
		@include title;
		text-align: center;
	}
	span
	{
		display: block;
		text-align: center;
		@include blockquote-text;
		font-size: 1.3rem;
	}
	.items
	{
		padding: $rule 0;
		justify-content: space-between;
		.newsitem
		{
			h3
			{
				border-bottom: 1px solid $color03;
				font-size: 1.8rem;
				font-weight: 100;
			}
			.newsitem-content
			{
				padding: $rule/2 0;

				.img-wrapper
				{

					img
					{
						display: block;
						width: 100%;
					}
				}

			}
		}
		@include breakpoint( 2 )
		{

			.newsitem
			{
				.newsitem-content
				{
					display: flex;
					.img-wrapper
					{
						width: 30%;
					}
					.wysywig
					{
						width: 70%;
						padding-left: $rule/2;
					}
				}
			}
		}
		@include breakpoint( 4 )
		{
			display: flex;
			.newsitem
			{
				width: 48%;
			}
		}
	}
}